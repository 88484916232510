/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Styled.h1 sx={{ textAlign: "center", mt: 4 }}>Sivua ei löytynyt</Styled.h1>
    <Styled.p sx={{ textAlign: "center" }}>
      Valitettavasti etsimääsi sivua ei löytynyt. Palaa takaisin{" "}
      <Styled.a as={Link} to="/">
        etusivulle
      </Styled.a>{" "}
      tai selaa{" "}
      <Styled.a as={Link} to="/tuotteet">
        tuotteitamme
      </Styled.a>
      .
    </Styled.p>
  </Layout>
)

export default NotFoundPage
